<template>
  <div class="read-main">
    <div class="read-head">
      <div class="read-head-content">
        <div class="read-head-content-title">文章类型</div>
        <div class="read-head-content-button">
          <div class="button-org" style="width: 136px;margin-right: 12px"
               v-show="!(doc.Translated_Status&&doc.Translated_Status==='ok')"
               @click="transDoc(doc)"
          >先翻译后查看译文
          </div>
          <div class="button-org" v-show="radio!=='commentsone'&&radio!=='commentstwo'"
               @click="radio==='commentsone'||radio==='commentstwo'?()=>'':handleOrgCommand('org')"
               :style="radio==='commentsone'||radio==='commentstwo'?'cursor: no-drop;':''"
          >下载原文
          </div>
          <div class="button-tran" v-show="doc.Translated_Status&&doc.Translated_Status==='ok'"
               @click="doc.Translated_Status&&doc.Translated_Status==='ok'?handleOrgCommand('tran'):()=>''"
               :style="doc.Translated_Status&&doc.Translated_Status==='ok'?'':'cursor: no-drop;'">
            下载译文
          </div>
          <div class="button-check">
            <el-radio v-model="radio" label="pdf">
              <span
                  style="font-size: 14px;font-family: Source Han Sans CN; font-weight: 400; color: rgb(51, 51, 51);">PDF</span>
            </el-radio>
            <el-radio v-model="radio" label="docx">
              <span
                  style="font-size: 14px;font-family: Source Han Sans CN; font-weight: 400; color: rgb(51, 51, 51);">WORD</span>
            </el-radio>
            <el-radio v-if="doc.Translated_Status&&doc.Translated_Status==='ok'" v-model="radio" label="commentsone">
              <span
                  style="font-size: 14px;font-family: Source Han Sans CN; font-weight: 400; color: rgb(51, 51, 51);">原文批准</span>
            </el-radio>
            <el-radio v-if="doc.Translated_Status&&doc.Translated_Status==='ok'" v-model="radio" label="commentstwo">
              <span
                  style="font-size: 14px;font-family: Source Han Sans CN; font-weight: 400; color: rgb(51, 51, 51);">译文批准</span>
            </el-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="read-content">
      <div class="read-content-pdf">
        <div style="display: flex;justify-content: flex-end;margin-right: 27px;">
          <img v-show="!trans" src="../assets/transZh.png" alt="" @click="transTo">
          <img v-show="trans" src="../assets/transEn.png" alt="" @click="transTo">
        </div>
        <pdf v-show="!trans"
             v-for="i in numPages"
             :src="orgPdf"
             :key="i"
             :page="i"
             style="width: 900px;overflow: auto"
        ></pdf>
        <pdf v-if="tranPdf!==undefined&&tranPdf!==''" v-show="trans"
             v-for="i in numPagesTran"
             :src="tranPdf"
             :key="i"
             :page="i"
             style="width: 900px;overflow: auto"
        ></pdf>
        <pdf v-if="tranPdf===undefined||tranPdf===''" v-show="trans"
             v-for="i in numPages"
             :src="orgPdf"
             :key="i"
             :page="i"
             style="width: 900px;overflow: auto"
        ></pdf>
      </div>
      <div class="read-content-info">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="详情" name="first">
            <div v-show="!trans"
                 style="line-height: 25px;width:100%;display: flex;flex-flow: column;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;">
              <div v-if="doc.Creator" style="margin-top: 20px">
                <span>作者：</span>
                <span v-for="(item,index) in doc.Creator.split('%%')||[]">
                  {{ item }}
                  {{ doc.Creator.split('%%').length - 1 !== index ? '  ' : '' }}</span>
              </div>
              <div v-if="doc.OriginalOrganization" style="margin-top: 12px">
                <span>作者单位：</span>
                <span v-for="(item,index) in doc.OriginalOrganization.split('%%')||[]">
                  {{ item }}
                  {{ doc.OriginalOrganization.split('%%').length - 1 !== index ? '  ' : '' }}</span>
              </div>
              <div v-if="doc.DOI" style="margin-top: 12px"><span>DOI：</span>{{ doc.DOI }}</div>
              <div v-if="doc.PublishYear" style="margin-top: 12px"><span>年、卷（期）：</span>{{ doc.PublishYear }}，{{
                  doc.Volum
                }}{{ doc.Issue ? "（" + doc.Issue + "）" : '' }}
              </div>
            </div>
            <div v-show="trans"
                 style="line-height: 25px;width:100%;display: flex;flex-flow: column;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;">
              <div v-if="doc.Creator_Translated" style="margin-top: 20px">
                <span>作者：</span>
                <span v-for="(item,index) in doc.Creator_Translated.split('%%')||[]">
                  {{ item }}
                  {{ doc.Creator_Translated.split('%%').length - 1 !== index ? '  ' : '' }}</span>
              </div>
              <div v-if="doc.OriginalOrganization_Translated" style="margin-top: 12px">
                <span>作者单位：</span>
                <span v-for="(item,index) in doc.OriginalOrganization_Translated.split('%%')||[]">
                  {{ item }}
                  {{ doc.OriginalOrganization_Translated.split('%%').length - 1 !== index ? '  ' : '' }}</span>
              </div>
              <div v-if="doc.DOI" style="margin-top: 12px"><span>DOI：</span>{{ doc.DOI }}</div>
              <div v-if="doc.PublishYear" style="margin-top: 12px"><span>年、卷（期）：</span>{{ doc.PublishYear }}，{{
                  doc.Volum
                }}{{ doc.Issue ? "（" + doc.Issue + "）" : '' }}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-if="downLoadList&&downLoadList.length>0" class="downs" style="
    height: 50px;
          z-index: 100;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 10px;
          right: 0;
          border-radius: 6px;">
      <div style="height: 50px;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
        <el-progress v-for="(item,index) in  downLoadList" :key="item.id" style="padding-left: 10px;padding-right: 10px"
                     :stroke-width="2" :color="'rgb(6, 63, 149)'"
                     status="success" :width="35" type="circle"
                     :percentage="50">
        </el-progress>
      </div>
    </div>
    <div v-if="isOk" style="
          height: 50px;
          z-index: 100;width: 330px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 75px;
          right: 0;
          border-radius: 6px;">
      <div style="height: 50px;width: 100%;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
        <span>翻译完成<span style="cursor: pointer;color: rgba(95, 138, 202, 1);margin-right: 22px"
                        @click="toList">去查看</span></span>
        <img src="../assets/OK.png" alt=""/>
      </div>
    </div>
    <div v-if="isShowTransDoc" style="
          z-index: 100;width: 330px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 140px;
          right: 0;
          border-radius: 6px;">
      <div class="isShowTransDoc" v-for="(item,index) in JSON.parse(isShowTransDoc)">
        <div
            style="color: #333333 !important;padding: 14px;">
          <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
            <div style="width: 200px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                 v-html="item.Title"></div>
            <img style="cursor: pointer" src="../assets/close.png" alt="" @click="closes(item.id)"/>
          </div>
          <el-progress :percentage="item.number?item.number:0" :format="format">
          </el-progress>
        </div>
      </div>
    </div>
    <el-dialog
        :top="'40vh'"
        :show-close="false"
        :visible.sync="setMailVisible"
        width="280px"
        center>
        <span slot="title"
              style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
           您暂未设置邮箱，请填写邮箱
        </span>
      <div style="width: 100%;display: flex;justify-content: center">
        <div
            style="width: 95px;height: 30px;background: #063F95;border-radius: 5px;display: flex;align-items: center;justify-content: center;">
            <span style="cursor: pointer;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #FFFFFF;"
                  @click="toSetMail">
              填写邮箱
            </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import classCode from "../../classCode.json";
import {
  checkStatusRequest,
  documentUrlRequest,
  downloadRequest,
  searchServiceClient,
  transDocRequest
} from "@/tools/search-api";
import pdf from 'vue-pdf'
import jwt from "jwt-decode";

export default {
  name: "ReadView",
  components: {
    pdf
  },
  data() {
    return {
      isOk: false,
      downLoadList: [],
      activeName: 'first',
      numPages: undefined,
      numPagesTran: undefined,
      classCode: classCode,
      id: '',
      collection: '',
      doc: {},
      data: [],
      orgPdf: '',
      tranPdf: '',
      trans: true,
      clas: '',
      downLoadItem: undefined,
      radio: 'pdf',
      oldToken: '',
      checkLogin: undefined,
      isShowTransDoc: undefined,
      transTimer: undefined,
      setMailVisible: false,
    }
  },
  created() {
    this.id = this.$route.query.id
    this.collection = this.$route.query.collection
    this.trans = this.$route.query.trans || true
    if (this.id && this.collection) {
      this.document()
    }
  },
  methods: {
    closes(id) {
      let isShowTransDoc = JSON.parse(this.isShowTransDoc)
      let docs = []
      isShowTransDoc.forEach((item, index) => {
        if (item.id === id) {
          return
        }
        docs.push(item)
      })
      if (docs.length === 0) {
        this.isShowTransDoc = undefined
        localStorage.removeItem("isShowTransDoc")
        return
      }
      this.isShowTransDoc = JSON.stringify(docs)
      localStorage.setItem("isShowTransDoc", this.isShowTransDoc)
    },
    toList() {
      this.isOk = false;
      this.$router.push('/list')
    },
    checkStatus() {
      if (this.isShowTransDoc) {
        let docs = JSON.parse(this.isShowTransDoc)
        let isOk = true
        for (let i = 0; i < docs.length; i++) {
          if (docs[i].number === 100) {
            continue
          }
          isOk = false
          let Range = 5 - 1;
          let Rand = Math.random();
          let num = 1 + Math.round(Rand * Range) + parseInt(docs[i].number || 0)
          docs[i].number = num > 99 ? 99 : num
          let request = checkStatusRequest
          request.setDocId(docs[i].id)
          let token = this.$cookies.get('token')
          if (!token) {
            token = localStorage.getItem('token');
          }
          searchServiceClient.checkStatus(request, {
            ['token']: token
          }).then(res => {
            if (res.getStatus()) {
              docs[i].number = 100;
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            } else {
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            }
          }).catch(err => {
            console.log(err)
          })
        }
        if (isOk) {
          clearInterval(this.transTimer)
          localStorage.removeItem("isShowTransDoc")
          this.isShowTransDoc = undefined;
          this.isOk = isOk
        }
      } else {
        clearInterval(this.transTimer)
      }
    },
    toSetMail() {
      window.open("https://user.wesitrans.com/account", '_blank')
    },
    transDoc(item) {
      debugger
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let isShowTransDoc = localStorage.getItem("isShowTransDoc")
      if (isShowTransDoc) {
        let docs = JSON.parse(isShowTransDoc)
        for (let i = 0; i < docs.length; i++) {
          if (!docs[i].id) {
            localStorage.removeItem("isShowTransDoc")
          } else {
            if (docs[i].id === item.id) {
              this.$message.warning("正在翻译啦，请不要着急！")
              return;
            }
          }
        }
        let request = transDocRequest
        request.setId(item.id)
        searchServiceClient.transDoc(request, {
          ['token']: token
        }).then(res => {
          if (res.getStatus() === "login") {
            this.showIframe('/loginTwo?code=haoyi')
            return
          }
          if (res.getStatus() === 'login-person') {
            this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
            this.showIframe('/loginTwo?code=haoyi')
            return;
          }
          if (res.getStatus() === "remote") {
            this.$message.error("当前账户不具备翻译权限,请联系销售人员！")
            return
          }
          if (res.getStatus() === "set_mail") {
            this.setMailVisible = true
            return
          }
          if (res.getStatus() === "fail") {
            this.$message.error("翻译失败，请稍后再试！")
            return
          }
          if (res.getStatus() === "time") {
            this.$message.error("您的机构使用时间已截至,请联系销售人员！")
            return
          }
          if (res.getStatus() === "success") {
            docs.push({
              id: item.id,
              Title: item.Title
            })
            localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
            this.isShowTransDoc = JSON.stringify(docs)
            return
          }
          this.$message.error(res.getStatus())
        }).catch(err => {
          this.$message.error("翻译异常，请稍后再试！")
        })
        return
      }
      let request = transDocRequest
      request.setId(item.id)
      searchServiceClient.transDoc(request, {
        ['token']: token
      }).then(res => {
        if (res.getStatus() === "login") {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getStatus() === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() === "remote") {
          this.$message.error("当前账户不具备翻译权限,请联系销售人员！")
          return
        }
        if (res.getStatus() === "set_mail") {
          this.setMailVisible = true
          return
        }
        if (res.getStatus() === "fail") {
          this.$message.error("翻译失败，请稍后再试！")
          return
        }
        if (res.getStatus() === "time") {
          this.$message.error("您的机构使用时间已截至,请联系销售人员！")
          return
        }
        if (res.getStatus() === "success") {
          localStorage.setItem("isShowTransDoc", JSON.stringify([{id: item.id, Title: item.Title}]))
          this.isShowTransDoc = JSON.stringify([{id: item.id, Title: item.Title}])
          return
        }
        this.$message.error(res.getStatus())
      }).catch(err => {
        this.$message.error("翻译异常，请稍后再试！")
      })
      clearInterval(this.transTimer)
      this.transTimer = setInterval(() => this.checkStatus(), 3000);
    },
    handleOrgCommand(clas) {
      debugger
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      this.downLoadList.push(this.doc)
      this.clas = clas
      this.downLoadItem = this.doc
      this.downloadDoc()
    },
    downloadDoc() {
      let request = downloadRequest;
      request.setId(this.downLoadItem.id);
      request.setType(this.radio !== 'pdf' && this.radio !== 'docx' ? 'docx' : this.radio)
      request.setComment(this.radio)
      request.setClasses(this.clas)
      request.setName(this.downLoadItem.Title)
      const that = this;
      const TOKEN_KEY = 'token';
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      let stream = searchServiceClient.download(request, {
        [TOKEN_KEY]: token
      });
      let flag = 0;
      let content = "";
      let title = "";
      let type = "";
      let message = '';
      stream.on('data', (res) => {
        message = res.getMessage()
        if (message === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (message === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() && flag === 0) {
          title = res.getTitle();
          type = res.getType();
        }
        if (!res.getStatus() && flag === 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: res.getMessage()
          });
          that.centerDialogVisible = false;
          return;
        }
        flag++;
        content += res.getContent_asB64();
      });
      stream.on('end', (end) => {
        if (message === 'login') {
          return
        }
        if (message === 'login-person') {
          return;
        }
        let aLink = document.createElement('a');
        let blob = that.base64ToBlob(content, type);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);
        aLink.download = title;
        aLink.href = window.URL.createObjectURL(blob)
        aLink.click();
        that.centerDialogVisible = false;
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.id === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
      });
      stream.on('error', (err) => {
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.id === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
        that.centerDialogVisible = false;
        if (err && err.code !== 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: '下载文档失败，请稍后再试！'
          })
        }
      });
    },
    base64ToBlob(code, type) {
      let bstr = atob(code)
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: type});
    },
    transTo() {
      if (this.doc.Translated_Status && this.doc.Translated_Status === 'ok') {
        this.trans = !this.trans
        return
      }
      this.$message.warning("全文还未翻译，请先翻译吧！")
    },
    handleClick(tab, event) {
    },
    checkLogins() {
      let token = this.$cookies.get("token")
      if (token && this.oldToken !== token) {
        clearInterval(this.checkLogin)
        this.oldToken = token
        localStorage.setItem('token', token)
        let auth = jwt(token)
        localStorage.setItem('auth-context', auth.userInfoList)
        window.location.reload()
      }
    },
    showIframe(url) {
      this.oldToken = this.$cookies.get("token")
      const width = $(window).width();
      if (width < 1100) {
        window.layer.open({
          skin: 'login-demo-class',
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      } else {
        window.layer.open({
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      }
      this.checkLogin = setInterval(this.checkLogins, 1000);
    },
    document() {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let request = documentUrlRequest;
      request.setId(this.id)
      searchServiceClient.documentUrl(request, {
        ['token']: token
      }).then(res => {
        if (res.getStatus() === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getStatus() === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getDocument()) {
          this.doc = JSON.parse(res.getDocument())[0]
        }
        if (res.getOrgUrl()) {
          this.orgPdf = pdf.createLoadingTask(res.getOrgUrl());
          this.orgPdf.promise.then(pdf => {
            this.numPages = pdf.numPages;
          });
        }
        if (res.getTranUrl()) {
          this.tranPdf = pdf.createLoadingTask(res.getTranUrl());
          this.orgPdf.promise.then(pdf => {
            this.numPagesTran = pdf.numPages;
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
    ,
  },
  destroyed() {
    clearInterval(this.checkLogin)
  }
}
</script>

<style lang="less" scoped>
.read-main {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: #ffffff;

  /deep/ .downs {
    .el-icon-check:before {
      content: url('');
      display: block;
      width: 14px;
      height: 14px;
      background: url('../assets/downico.png');
      background-size: cover;
    }
  }

  .read-head {
    width: 100%;
    height: 80px;
    background: rgba(221, 221, 221, 0.99);
    display: flex;
    justify-content: center;

    .read-head-content {
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .read-head-content-title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }

      .read-head-content-button {
        display: flex;
        justify-content: space-between;

        .button-org {
          width: 80px;
          height: 30px;
          border: 1px solid #063F95;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #063F95;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .button-org:hover {
          background: #063F95;
          color: #FFFFFF;
        }

        .button-tran {
          width: 80px;
          height: 30px;
          border: 1px solid #063F95;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #063F95;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 12px;
          cursor: pointer;
        }

        .button-tran:hover {
          background: #063F95;
          color: #FFFFFF;
        }

        /deep/ .button-check {
          min-width: 165px;
          height: 30px;
          display: flex;
          align-items: center;
          margin-left: 25px;

          .el-radio__label {
            padding-left: 10px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666 !important;
          }

          .el-radio__inner {
            width: 20px;
            height: 20px;
            background: #F5F5F5;
            border: 1px solid #DDDDDD;
            border-radius: 10px;
          }

          .el-radio__inner::after {
            width: 9px;
            height: 9px;
            background: #063F95;
            border-radius: 4px;
          }
        }
      }
    }
  }

  /deep/ .read-content {
    width: 1200px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    .el-tabs__item.is-active {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #063F95;
    }

    .el-tabs__nav-wrap::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: #E4E7ED;
      z-index: 1;
    }

    .el-tabs__active-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      background-color: #063F95;
      z-index: 1;
      transition: transform .3s cubic-bezier(.645, .045, .355, 1);
      list-style: none;
    }

    .read-content-pdf {
      width: 900px;
      overflow-y: auto;
      height: 1000px;
      overflow-x: hidden;
    }

    .read-content-pdf::-webkit-scrollbar {
      width: 4px;
    }

    .read-content-pdf::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    .read-content-pdf::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .read-content-info {
      width: 280px;
    }
  }
}
</style>